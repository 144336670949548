import React from 'react';
import { Link } from 'react-router-dom';

export default function CaravanaItem({ caravana }) {
    return (
        <li key={caravana.id} className="caravanas__item">
            <img src={caravana.attributes.img.data?.attributes.url} alt="Imagem da Cidade" />
            <div className="caravanas__text-button-box">
                <div className="caravanas__text-box">
                    <p className='caravanas__item-type'>
                        {caravana.attributes.cafe_com_espiritualidade 
                            ? 'Café com Espiritualidade' 
                            : caravana.attributes.terapias_holisticas
                            ? 'Terapias Holísticas'
                            : 'Caravanas de Luz'}
                    </p>
                    <h6 className="caravanas__item-title">{caravana.attributes.nome}</h6>
                    <p className="caravanas__item-subtitle">{caravana.attributes.data}</p>
                </div>

                <Link to={`/caravanas/${caravana.id}`}><button className="caravanas__item-button">Saber mais</button></Link>
            </div>
        </li>
    );
}
