import React from 'react';
import { Link } from 'react-router-dom';

export default function CursoItem({ curso }) {
    return (
        <li key={curso.id} className="cursos__item">
            <img src={curso.attributes.img.data?.attributes.url} alt="Imagem da Cidade" />
            <div className="cursos__text-button-box">
                <div className="cursos__text-box">
                    <h6 className="cursos__item-title">{curso.attributes.nome}</h6>
                    <p className="cursos__item-subtitle">{curso.attributes.data}</p>
                </div>

                <Link to={`/cursos/${curso.id}`}><button className="cursos__item-button">Saber mais</button></Link>
            </div>
        </li>
    );
}
