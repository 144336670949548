import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_STRAPI_URL + '/api';

const cursosApi = createApi({
    reducerPath: 'cursos',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: (headers) => {
            headers.set("Authorization", process.env.REACT_APP_STRAPI_TOKEN_API_KEY);
            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchCursos: builder.query({
                query: () => {
                    return {
                        url: "cursos?populate=*&pagination[limit]=100",
                        method: "GET",
                    };
                }
            }),
            fetchCursosById: builder.query({
                query: id => {
                    return {
                        url: `cursos/${id}?populate=*`,
                        method: "GET"
                    }
                }
            })
        }
    }
});

export const {
    useFetchCursosQuery,
    useFetchCursosByIdQuery
} = cursosApi;
export { cursosApi };
