import React, { useState } from 'react';
import CursoItem from '../CursoItem';

export default function CursoList({ data, resultRef }) {

    const [itemsToShow, setItemsToShow] = useState(5);

    const showMore = () => {
        setItemsToShow(data.length);
    }

    const showLess = () => {
        setItemsToShow(5);
        resultRef.current.scrollIntoView({ behavior: "smooth" });
    }

    let content = '';

    if (data.length > 0) {
        content = <>
            {
                data.slice(0, itemsToShow).map(curso => {
                    return (
                        <CursoItem key={curso.id} curso={curso} />
                    );
                })
            }

            {data.length > 4 && (itemsToShow === data.length 
                ? <button className='cursos__length-button' onClick={showLess}>Mostrar menos</button> 
                : <button className='cursos__length-button' onClick={showMore}>Carregar mais Cursos</button>)}
        </>
    }

    return (
        <ul ref={resultRef} className="cursos__list">
            {content}
        </ul>
    );
}
