import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { caravanasApi } from "./apis/caravanasApi";
import { cursosApi } from "./apis/cursosApi"; // Adicionado para "Cursos"
import { produtosApi } from "./apis/produtosApi";
import { ajudeTemsApi } from "./apis/ajudeTemsApi";
import { ultimasNoticiasApi } from "./apis/ultimasNoticiasApi";
import { atendimentosApi } from "./apis/atendimentosApi";
import { rituaisTemsApi } from "./apis/rituaisTemsApi";
import { precesApi } from "./apis/precesApi";
import { eventosApi } from "./apis/eventosApi"; // Adicionado para "Eventos"

export const store = configureStore({
  reducer: {
    [ajudeTemsApi.reducerPath]: ajudeTemsApi.reducer,
    [caravanasApi.reducerPath]: caravanasApi.reducer,
    [cursosApi.reducerPath]: cursosApi.reducer, // Adicionado o reducer de "Cursos"
    [produtosApi.reducerPath]: produtosApi.reducer,
    [precesApi.reducerPath]: precesApi.reducer,
    [rituaisTemsApi.reducerPath]: rituaisTemsApi.reducer,
    [ultimasNoticiasApi.reducerPath]: ultimasNoticiasApi.reducer,
    [atendimentosApi.reducerPath]: atendimentosApi.reducer,
    [eventosApi.reducerPath]: eventosApi.reducer, // Adicionado o reducer de "Eventos"
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(ajudeTemsApi.middleware)
      .concat(caravanasApi.middleware)
      .concat(cursosApi.middleware) // Adicionado o middleware de "Cursos"
      .concat(produtosApi.middleware)
      .concat(precesApi.middleware)
      .concat(rituaisTemsApi.middleware)
      .concat(atendimentosApi.middleware)
      .concat(ultimasNoticiasApi.middleware)
      .concat(eventosApi.middleware); // Adicionado o middleware de "Eventos"
  },
});
setupListeners(store.dispatch);

// Exportação de hooks existentes
export {
  useFetchCaravanasQuery,
  useFetchCaravanasByIdQuery,
} from "./apis/caravanasApi";
export {
  useFetchPrecesQuery,
  useFetchPrecesByIdQuery,
} from "./apis/precesApi";
export {
  useFetchEventosQuery,
  useFetchEventosByIdQuery,
} from "./apis/eventosApi";
export { useFetchProductsQuery } from "./apis/produtosApi";
export {
  useFetchRituaisQuery,
  useFetchRituaisByIdQuery,
  useFetchVelasQuery,
} from "./apis/rituaisTemsApi";
export { useFetchDonationPercentageQuery } from "./apis/ajudeTemsApi";
export {
  useFetchNewsQuery,
  useFetchRitualsNewsQuery,
} from "./apis/ultimasNoticiasApi";
export {
  useFetchAtendimentosQuery,
  useFetchAtendimentosByCityIdQuery,
} from "./apis/atendimentosApi";
export {
  useFetchCursosQuery,
  useFetchCursosByIdQuery,
} from "./apis/cursosApi";