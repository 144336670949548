import ServiceItem from "./ServiceItem";
import algodaoImg from "assets/algodao.png";
import antepassadosImg from "assets/antepassados.png";
import ajudeImg from "assets/ajude.png";
import { useFetchRitualsNewsQuery } from "store";

function ServicesList() {

    const {data} = useFetchRitualsNewsQuery();
    let renderedServices;

    if(data){
        let ritualDoMes = data.data[0].attributes.ritual_do_mes.data[0];

        const services = [
            {
                name: 'Ritual do Mês',
                img: ritualDoMes.attributes.img.data.attributes.url,
                path: `/rituais/${ritualDoMes.id}`,
            },
            {
                name: 'Nomes para Cerimônia dos Antepassados',
                img: antepassadosImg,
                path: '/cerimonia-antepassados'
            },
            {
                name: 'Ajude o TEMS',
                img: ajudeImg,
                path: 'https://ajude.tems.com.br/donations/nosso-sonho/'
            },
            {
                name: 'Nomes para Cerimônia do Algodão',
                img: algodaoImg,
                path: '/cerimonia-algodao',
            },
        ];

        renderedServices = services.map((service) => {
            return <ServiceItem key={service.name} name={service.name} img={service.img} path={service.path}/>
        });

    }
    

    return (
        <div className="outros-servicos">
            {renderedServices}
        </div>
    );
}

export default ServicesList;