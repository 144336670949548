import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_STRAPI_URL + '/api';

const eventosApi = createApi({
    reducerPath: 'eventos',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: headers => {
            headers.set("Authorization", process.env.REACT_APP_STRAPI_TOKEN_API_KEY);
            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchEventos: builder.query({
                query: () => {
                    return {
                        url: "eventos?populate=*",
                        method: "GET"
                    };
                }
            }),
            fetchEventosById: builder.query({
                query: id => {
                    return {
                        url: `eventos/${id}?populate=*`,
                        method: "GET"
                    }
                }
            })
        }
    }
});

export const {
    useFetchEventosQuery,
    useFetchEventosByIdQuery
} = eventosApi;
export { eventosApi };
