import { useEffect } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useParams } from "react-router-dom";
import { useFetchEventosByIdQuery } from "store";

export default function Evento() {
    const { id } = useParams();
    const { data } = useFetchEventosByIdQuery(id);

    useEffect(() => {
        if (data && data.data && data.data.attributes) {
            const tipo = data.data.attributes.tipo;
            const titulo = data.data.attributes.titulo;

            // Verificar se tipo e titulo estão disponíveis antes de tentar manipulá-los
            if (tipo && titulo) {
                document.title = `${tipo.charAt(0).toUpperCase() + tipo.slice(1)} ${titulo}`;
            }
        }
    }, [data]);

    let content;

    if (data && data.data && data.data.attributes) {
        const { texto, video, img } = data.data.attributes;

        // Verifica se a URL do vídeo é válida (por exemplo, YouTube)
        const renderVideo = (videoUrl) => {
            if (videoUrl) {
                const videoId = videoUrl.split("v=")[1]; // para YouTube
                if (videoId) {
                    return (
                        <iframe
                            width="857px"
                            height="480px"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="Video"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    );
                }
            }
            return null;
        };

        content = (
            <div className="evento">
                <section className="evento__header" style={{
                    backgroundImage: `url(${img.data.attributes.url})`
                }}></section>
                <div className="evento__content"> <h4>{data.data.attributes.titulo}</h4> <ReactMarkdown>{data.data.attributes.texto}</ReactMarkdown> </div>
                {/* Verifica se o vídeo existe e, em seguida, renderiza dentro de um container para centralização */}
                {video && (
                    <div className="evento__video-container">
                        {renderVideo(video)}
                    </div>
                )}
            </div>
        );
    }

    return content;
}
