import "./sass/main.scss";
import Header from "./components/ui/Header/Header";
import Footer from "./components/ui/Footer/Footer";
import HomePage from "./pages/Home";
import AtendimentosPage from './pages/Atendimentos/AtendimentosPage';
import ComoAjudarPage from "./pages/Como Ajudar";
import PrecePage from "./pages/Preces";
import EventoPage from "pages/Eventos";
import ContatoPage from "./pages/Contato";
import RitualDoMesPage from "./pages/Ritual Mes";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import CaravanasDeLuzPage from "pages/Caravanas de Luz";
import CursosDeLuzPage from "pages/Cursos";
import CaravanaEscolhida from "pages/Caravanas de Luz/CaravanaEscolhida";
import AtendimentoSaoPaulo from "pages/Atendimentos/AtendimentoSaoPaulo";
import { useEffect, useRef, useState } from "react";
import AtendimentoSede from "pages/Atendimentos/AtendimentoSede";
import ReactGA from 'react-ga';
import NotFound from "pages/NotFound";
import Pix from "pages/Como Ajudar/Pix";
import Ritual from "pages/Ritual Mes/Ritual";
import Prece from "pages/Preces/Prece";
import Evento from "pages/Eventos/Evento";
import PoliticaPrivacidade from "pages/PoliticaPrivacidade";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import CerimoniaAlgodaoPage from "pages/CerimoniaAlgodao";
import CerimoniaAntepassadosFormPage from "pages/CerimoniaAntepassadosFormPage";
import SacolinhasNatal from "pages/SacolinhasNatal";
import SacolinhasVideo from "assets/Sacolinha-video.mp4";
import CursoEscolhido from "pages/Cursos/CursoEscolhido";

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function App() {
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const videoRef = useRef(null); 

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    if (isOpen && videoRef.current) {
      videoRef.current.currentTime = 0; 
      videoRef.current.play(); 
    }
  }, [isOpen]);

  return (
    <div className="container">
      <Header />

      {/* <Popup open={isOpen} modal nested>
        {close => (
          <a 
            href="https://ajude.tems.com.br/donations/cestas-de-natal-2024/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setIsOpen(false)}
          >
            <div className="modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="content" style={{ cursor: 'pointer' }}>
                <video
                  ref={videoRef}
                  autoPlay
                  loop
                  muted
                >
                  <source src={SacolinhasVideo} type="video/mp4" />
                  Seu navegador não suporta a tag de vídeo.
                </video>
              </div>
            </div>
          </a>
        )}
      </Popup> */}

      {/* Definição das rotas da aplicação */}
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/atendimentos" element={<AtendimentosPage />} >
          <Route path="" element={<Navigate to="sede/1" replace />} />
          <Route exact path="sao-paulo" element={<AtendimentoSaoPaulo />} />
          <Route exact path="sede/:id" element={<AtendimentoSede />} />
        </Route>
        <Route exact path="/caravanas" element={<CaravanasDeLuzPage />} />
        <Route exact path="/caravanas/:id" element={<CaravanaEscolhida />} />
        <Route exact path="/cursos" element={<CursosDeLuzPage />} />
        <Route exact path="/cursos/:id" element={<CursoEscolhido />} />
        <Route exact path="/como-ajudar" element={<ComoAjudarPage />} />
        <Route exact path="/rituais" element={<RitualDoMesPage />} />
        <Route exact path="/rituais/:id" element={<Ritual />} />
        <Route exact path="/preces" element={<PrecePage />} />
        <Route exact path="/preces/:id" element={<Prece />} />
        <Route exact path="/eventos" element={<EventoPage />} />
        <Route exact path="/eventos/:id" element={<Evento />} />
        <Route exact path="/contato" element={<ContatoPage />} />
        <Route exact path="/pix/:tipo" element={<Pix />} />
        <Route exact path="/politica-privacidade" element={<PoliticaPrivacidade />} />
        <Route exact path="/cerimonia-algodao" element={<CerimoniaAlgodaoPage />} />
        <Route exact path="/cerimonia-antepassados" element={<CerimoniaAntepassadosFormPage />} />
        <Route exact path="/sacolinhas" element={<SacolinhasNatal />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
