import CursoList from './CursoList';
import { useFetchCursosQuery } from 'store';
import { useEffect, useState } from 'react';

function CursosDeLuzPage() {
    const { data } = useFetchCursosQuery();
    const [cursos, setCursos] = useState([]);
    const [selectedState, setSelectedState] = useState('Ver todos'); // Estado selecionado

    useEffect(() => {
        if (data) {
            // Ordena os cursos pela data_curso ao carregar a página
            const sortedCursos = [...data.data].sort(
                (a, b) => new Date(a.attributes.data_curso) - new Date(b.attributes.data_curso)
            );
            setCursos(sortedCursos);  // Atualiza o estado com os cursos ordenados
        }
    }, [data]);

    const estados = [
        'Ver todos', 'Acre', 'Alagoas', 'Amapá', 'Amazonas', 'Bahia', 'Ceará', 
        'Distrito Federal', 'Espírito Santo', 'Goiás', 'Maranhão', 'Mato Grosso', 
        'Mato Grosso do Sul', 'Minas Gerais', 'Pará', 'Paraíba', 'Paraná', 'Pernambuco', 
        'Piauí', 'Rio de Janeiro', 'Rio Grande do Norte', 'Rio Grande do Sul', 
        'Rondônia', 'Roraima', 'Santa Catarina', 'São Paulo', 'Sergipe', 'Tocantins'
    ];

    const cidadesFiltro = (estadoEscolhido, event) => {
        clearSelection(); // Remove seleção anterior
        const el = event.target;
        el.classList.add('cursos__filter-cidade-tag-selected');
        setSelectedState(estadoEscolhido); // Atualiza o estado selecionado

        let cursosFiltered;

        if (estadoEscolhido !== 'Ver todos') {
            cursosFiltered = data.data.filter(
                (curso) => curso.attributes.estado === estadoEscolhido
            );
        } else {
            cursosFiltered = [...data.data]; // Mostra todos se "Ver todos" for clicado.
        }

        // Ordena os cursos pela data_curso após o filtro
        const sortedCursos = cursosFiltered.sort(
            (a, b) => new Date(a.attributes.data_curso) - new Date(b.attributes.data_curso)
        );

        setCursos(sortedCursos); // Atualiza o estado com os cursos filtrados e ordenados
    };

    const clearSelection = () => {
        const tags = document.querySelectorAll('.cursos__filter-cidade-tag');
        tags.forEach((tag) => {
            tag.classList.remove('cursos__filter-cidade-tag-selected');
        });
    };

    // Função para limpar filtros e resetar a lista de cursos
    const clearFilters = () => {
        clearSelection(); // Limpa as seleções de filtros
        setSelectedState('Ver todos'); // Reseta o estado selecionado para "Ver todos"

        // Recarrega todos os cursos e ordena pela data_curso
        const sortedCursos = [...data.data].sort(
            (a, b) => new Date(a.attributes.data_curso) - new Date(b.attributes.data_curso)
        );
        setCursos(sortedCursos); // Atualiza o estado com todos os cursos ordenados
    };

    let content = '';
    if (data) {
        content = <CursoList data={cursos} />;
    }

    return (
        <div className="cursos">
            <div className="cursos__header">
                <h4 className="cursos__titulo">Cursos</h4>
            </div>

            <main>
                <div className="cursos__filter">
                    <button 
                        className="cursos__filter-clear-button" 
                        onClick={clearFilters} // Chama a função de limpar filtros
                    >
                        Limpar
                    </button>
                    <p className="cursos__filter-estados-text">Estados</p>
                    {estados.map((estado) => (
                        <span
                            onClick={(event) => cidadesFiltro(estado, event)}
                            className={`cursos__filter-cidade-tag ${estado === selectedState ? 'cursos__filter-cidade-tag-selected' : ''}`}
                            key={estado}
                        >
                            {estado}
                        </span>
                    ))}
                </div>

                <div className="cursos__list-box">
                    <p className="cursos__text">Todos os próximos Cursos</p>
                    {content}
                </div>
            </main>
        </div>
    );
}

export default CursosDeLuzPage;
