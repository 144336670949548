import { Outlet, Link, NavLink } from "react-router-dom";

function AtendimentosPage() {
    return (
        <div className="atendimentos">
            {/* <nav className="atendimentos__nav">
                <ul className="atendimentos__list">
                    <NavLink className={(navData) => navData.isActive ? 'atendimentos__item atendimentos__item-selected' : 'atendimentos__item'} to="sao-paulo">São Paulo/SP</NavLink>
                    <NavLink className={(navData) => navData.isActive ? 'atendimentos__item atendimentos__item-selected' : 'atendimentos__item'} to="sede/3">Ribeirão Preto/SP</NavLink>
                    <NavLink className={(navData) => navData.isActive ? 'atendimentos__item atendimentos__item-selected' : 'atendimentos__item'} to="sede/1">Caxias do Sul/RS</NavLink>
                </ul>
            </nav> */}
            <Outlet />
        </div>
    );
}

export default AtendimentosPage;