import Arrow from 'assets/icones-algodao/ep_arrow-up-bold.png';
import { useState, useEffect } from 'react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from 'axios';


export default function CerimoniaAntepassadosFormPage() {
  const [mensagem, setMensagem] = useState('');
  const [envioHabilitado, setEnvioHabilitado] = useState(false);

  const [dados, setDados] = useState({
    name: '',
    email: '',
    names: '',
    acceptedTerms: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post(process.env.REACT_APP_GOOGLESHEETS_URL + 'antepassados', {
        nome: dados.name,
        email: dados.email,
        conteudo: dados.names.replace(/, /g, ',').replace(/,/g, '\n'),
      });

      setMensagem('Dados enviados com sucesso!');
      clear();
    } catch (error) {
      setMensagem('Não foi possível enviar os dados.');
    }
  };

  const clear = () => {
    setDados({
      name: '',
      email: '',
      names: '',
      acceptedTerms: false,
    });
  };

  useEffect(() => {
    const todosCamposPreenchidos = dados.name !== '' && dados.email !== '' && dados.names !== '';
    const termosAceitos = dados.acceptedTerms;
    setEnvioHabilitado(todosCamposPreenchidos && termosAceitos);
  }, [dados]);

  const handleChange = (name, value) => {
    setDados({
      ...dados,
      [name]: value,
    });
  };

  return (
    <div className="antepassados">
      {/* Header */}
      <section className="antepassados__header">
        <div className="antepassados__header-text-box">
          <h2 className="antepassados__header-title">Cerimônia dos Antepassados</h2>
          <span className="antepassados__header-saibamais">
            Inscreva-se abaixo!
          </span>
        </div>
      </section>

      {/* Formulário centralizado */}
      <div className="antepassados__form-container">
        <form className="antepassados__form" onSubmit={handleSubmit}>
          <div className="antepassados__input-box">
            <label>Nome Completo</label>
            <input
              type="text"
              value={dados.name}
              onChange={(event) => handleChange('name', event.target.value)}
            />
          </div>

          <div className="antepassados__input-box">
            <label>Email</label>
            <input
              type="email"
              value={dados.email}
              onChange={(event) => handleChange('email', event.target.value)}
            />
          </div>

          <div className="antepassados__input-box">
            <label>Nomes para Cerimônia</label>
            <textarea
              placeholder="Escreva os nomes separados por vírgula..."
              value={dados.names}
              onChange={(event) => handleChange('names', event.target.value)}
            />
            {mensagem && <div style={{ marginTop: '10px' }}>{mensagem}</div>}
          </div>

          <div className="antepassados__input-checkbox">
            <input
              type="checkbox"
              checked={dados.acceptedTerms}
              onChange={(event) => handleChange('acceptedTerms', event.target.checked)}
            />
            Eu aceito os termos da Política de Privacidade
          </div>

          <button className="antepassados__button" disabled={!envioHabilitado}>
            Enviar nomes
          </button>
        </form>
      </div>

      {/* Footer */}
      <footer className="antepassados__footer">
        <p>© 2024 Templo Espiritual Maria Santíssima. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
}
