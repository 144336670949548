import temsLogo from 'assets/LOGO.png';
import searchIcon from 'assets/search.png';
import { Link, NavLink } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';

function Header({ resultRef }) {

    const [isOpen, setOpen] = useState(false);

    const onSearchHandle = () => {};

    return (
        <header className="header">
            <Link className='header__logo' to="/">
                <img src={temsLogo} alt='TEMS Logo' />
            </Link>
            <ul className='header__list' style={isMobile ? (isOpen ? { display: 'flex' } : { display: 'none' }) : {}}>

                <NavLink className={(navData) => navData.isActive ? 'header__item active' : 'header__item'} to="/atendimentos/sao-paulo"><li>Agenda de Atendimentos</li></NavLink>
                <NavLink className={(navData) => navData.isActive ? 'header__item active' : 'header__item'} to="/eventos">Eventos</NavLink>
                <NavLink className={(navData) => navData.isActive ? 'header__item active' : 'header__item'} to="/caravanas"><li>Caravanas de Luz</li></NavLink>
                <NavLink className={(navData) => navData.isActive ? 'header__item active' : 'header__item'} to="/Cursos"><li>Cursos</li></NavLink>
                <NavLink className={(navData) => navData.isActive ? 'header__item active' : 'header__item'} to="/como-ajudar"><li>Como Ajudar</li></NavLink>
                <NavLink className={(navData) => navData.isActive ? 'header__item active' : 'header__item'} to="/rituais"><li>Rituais</li></NavLink>
                <NavLink className={(navData) => navData.isActive ? 'header__item active' : 'header__item'} to="/preces">Preces e Orações</NavLink>
                <a className='header__item' href="https://wa.me/+5511998117698" target="_blank" rel="noopener noreferrer">Contato</a>
                <a className='header__item' target='_blank' href='https://temsonline.com.br' rel="norefereer" >
                    <li>TemsOnline</li>
                </a>

            </ul>
            {/*<div className='header__search-box' style={isMobile ? (isOpen ? { display: 'flex' } : { display: 'none' }) : {}}>
                <img src={searchIcon} alt='Botao de Pesquisar' className='header__input--icon' />
                <input type="text" className='header__input' placeholder='Buscar' onClick={onSearchHandle} />
    </div>*/}

            {isMobile ? <Hamburger toggled={isOpen} toggle={() => setOpen(!isOpen)} color="white" /> : ""}
        </header>
    );
}

export default Header;
