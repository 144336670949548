import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper";
import SwiperStoreButtonPrev from "../../components/ui/Swiper/SwiperStoreButtonPrev";
import SwiperStoreButtonNext from "../../components/ui/Swiper/SwiperStoreButtonNext";
import BannerEventos from "../../assets/Banner_Eventos.svg";
import { useFetchEventosQuery } from "store";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function EventoPage() {
    const { data } = useFetchEventosQuery();

    useEffect(() => {
        document.title = 'Eventos';
    }, []);

    let content;

    if (data) {
        const eventos = data.data;

        content = (
            <div className="eventos">
                <div className="eventos__header">
                    <img src={BannerEventos} className="eventos__header-image" alt="Eventos Banner" />
                </div>
                <h4 className="eventos__title">PRÓXIMOS EVENTOS</h4>
                <section>
                    <div className="eventos__swiper-box">
                        <Swiper
                            modules={[Navigation, A11y, Autoplay]}
                            autoplay={{ delay: 8000, disableOnInteraction: false }}
                            loop={true}
                            slidesPerView={4}
                            breakpoints={{
                                300: { slidesPerView: 1 },
                                1200: { slidesPerView: 4 },
                            }}
                            spaceBetween={40}
                            navigation={{
                                nextEl: '.loja-tems__arrow-right',
                                prevEl: '.loja-tems__arrow-left',
                            }}
                        >
                            {eventos.map((item) => {
                                const evento = item.attributes;
                                const imgUrl = evento.img?.data?.attributes?.url || "/path/to/placeholder.jpg";
                                const titulo = evento.titulo || "Sem título";

                                return (
                                    <SwiperSlide key={item.id}>
                                        <Link to={`/eventos/${item.id}`}>
                                            <figure className="eventos__item">
                                                <img src={imgUrl} alt={titulo} />
                                                <figcaption>{titulo}</figcaption>
                                            </figure>
                                        </Link>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <SwiperStoreButtonPrev />
                        <SwiperStoreButtonNext />
                    </div>
                </section>
            </div>
        );
    }

    return content;
}

export default EventoPage;

